import { Link } from '@remix-run/react';
import { useIsAuthenticated } from '~/utils/user.js';
import { useAppMode } from './dashboard/app-mode-provider.tsx';
import { Button } from './ui/button.tsx';

function useAppButtonContent() {
  const isAuthenticated = useIsAuthenticated();
  const isHostRoute = useAppMode() === 'host';

  if (!isAuthenticated) {
    return {
      text: 'Get Started',
      to: '/signup',
    };
  }

  return {
    text: 'Log-in',
    to: isHostRoute ? '/app/host' : '/app',
  };
}

export function AppButton({ className }: { className?: string }) {
  const { to, text } = useAppButtonContent();

  return (
    <Button asChild variant="gradient" className={className}>
      <Link className="whitespace-nowrap" to={to} prefetch="intent">
        {text}
      </Link>
    </Button>
  );
}
